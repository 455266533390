import { Component } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	Card,
	CardHeader,
	Paper,
	Select,
	MenuItem,
	ListItemText,
	CircularProgress,
} from "@mui/material";
// cmp
import ImportTable from "./ImportTable";
import TableEditor from "./table-editor";
import Svg from "../svg";
import withNavigateAndParams from "../withNavigateAndParams";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/utils";
import { Storage, StorageKeys } from "../../services/storage";
import { muiTheme } from "@local/theme";

class TEditorSelector extends Component {

	constructor(props) {
		super(props);

		let selectedTable;
		if (this.props.params.tableId) {
			Storage.set(StorageKeys.teditorTable, this.props.params.tableId);
			selectedTable = this.props.params.tableId;
		} else if (Storage.get(StorageKeys.teditorTable)) {
			selectedTable = Storage.get(StorageKeys.teditorTable);
		} else {
			selectedTable = null;
		}

		this.state = {
			loading: true,
			tables: [],
			selectedTable: selectedTable,
			error: null,
		};

		this.handleTableChange = this.handleTableChange.bind(this);
	}

	componentDidMount() {
		this.fetchTables();
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.tableId && this.props.params.tableId !== prevProps.params.tableId) {
			Storage.set(StorageKeys.teditorTable, this.props.params.tableId);
			this.setState({
				selectedTable: this.props.params.tableId,
			});
		}
	}

	fetchTables() {
		Gupport.getTableNames({}, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					loading: false,
					error: error || msg.payload.data,
				});
			} else {
				const tables = msg.payload.data
					.filter((table) => (Gupport.user.table_read.includes(table.id)))
					.sort((a, b) => (sortAlphabetically(a.desc, b.desc)));

				this.setState({
					loading: false,
					tables: tables,
					error: null,
				});
				if (tables.length > 0 && this.state.selectedTable === null) {
					this.props.navigate(`/teditor/${tables[0].id}`);
				}
			}
		});
	}

	handleTableChange(event) {
		this.props.navigate(`/teditor/${event.target.value}`);
	}

	render() {
		const { t } = this.props;

		if (this.props.params.tableId === undefined && this.state.selectedTable !== null) {
			return <Navigate to={`/teditor/${this.state.selectedTable}`} />;
		}

		if (this.state.loading) {
			return <CircularProgress />;
		}

		const style = {
			marginTop: "16px",
			fontWeight: "bold",
			padding: "16px",
		};
		if (this.state.error) {
			return <div style={style}>{t("generic.error")}</div>;
		}

		const hasWriteAccess = Gupport.user && Gupport.user.table_write && Gupport.user.table_write.includes(this.state.selectedTable);

		return (
			<>
				<Paper style={{ display: "flex", alignItems: "center", padding: "12px" }}>
					<div style={{ flexGrow: 1, margin: "0 16px", fontWeight: "bold" }}>{t("teditor.table")}</div>
					<Select
						value={this.state.selectedTable}
						onChange={this.handleTableChange}
						style={{ flexGrow: 1, margin: "0 16px" }}
					>
						{
							this.state.tables.map((table) => (
								<MenuItem key={table.id} value={table.id}>
									<ListItemText primary={table.desc} />
								</MenuItem>
							))
						}
					</Select>
					{Storage.get(StorageKeys.expertMode) && <ImportTable tables={this.state.tables} />}
				</Paper>
				{(this.state.tables.length === 0)
					? <div style={style}>{t("teditor.noTables")}</div>
					: this.state.tables.some((table) => (table.id === this.state.selectedTable))
						?
						<>
							{!hasWriteAccess &&
								<Card style={{ marginTop: "12px", color: muiTheme.palette.text.primary, backgroundColor: muiTheme.palette.primary.main }}>
									<CardHeader
										title={t("customtEditor.errors.rbac_not_authorized")}
										avatar={<Svg src="messages/error.svg" />}
									/>
								</Card>
							}
							<TableEditor tableId={this.state.selectedTable} hasWriteAccess={hasWriteAccess} />
						</>
						: <div style={style}>{t("teditor.noSelectedTable")}</div>
				}
			</>
		);
	}

}

TEditorSelector.propTypes = {
	navigate: PropTypes.func.isRequired,
	params: PropTypes.shape({
		tableId: PropTypes.string,
	}).isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(withNavigateAndParams(TEditorSelector));
