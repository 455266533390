import PropTypes from "prop-types";
import { Card, CardContent, ListSubheader } from "@mui/material";
// types
import type { ReactNode } from "react";

type Props = {
	title: string;
	children: ReactNode;
};

const PlaygroundCard = (props: Props) => (
	<div style={{padding: "2px", marginTop: "12px"}}>
		<Card>
			<ListSubheader>{props.title}</ListSubheader>
			<CardContent>
				{props.children}
			</CardContent>
		</Card>
	</div>
);

PlaygroundCard.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default PlaygroundCard;
