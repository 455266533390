import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	Link,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	MenuItem,
	Typography,
	ListItemIcon,
	Select,
	IconButton,
	TextField,
	Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import Svg from "../../../svg";
// services
import Constants from "../../../../services/constants";
import { icons } from "@local/theme";

type Props = {
	selectedItem?: {
		device_info: string;
		mfg_fw: string;
		mfg_help: string;
		mfg_model: string;
		mfg_name: string;
		public_mfg_fw: string;
		public_mfg_model: string;
		public_mfg_name: string;
	};
	previousItem: {
		device_info: string;
		mfg_fw: string;
		mfg_help: string;
		mfg_model: string;
		mfg_name: string;
		public_mfg_fw: string;
		public_mfg_model: string;
		public_mfg_name: string;
	};
	fieldUpdateCount: number;
	fieldUpdate: (event: any) => void;
};

const ManufacturerSection = (props: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.manufacturer")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{props.fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.device_info.text")} subTitle={t("customtEditor.device_info.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.device_info !== props.selectedItem.device_info &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.device_info || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<Select
						name="device_info"
						value={props.selectedItem.device_info}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					>
						{
							Constants.DeviceInfoValues.map((value) => (
								<MenuItem key={value} value={value}>
									<ListItemText primary={value} />
								</MenuItem>
							))
						}
					</Select>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_name.text")} subTitle={t("customtEditor.mfg_name.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.mfg_name !== props.selectedItem.mfg_name &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.mfg_name || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="mfg_name"
						value={props.selectedItem.mfg_name || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_model.text")} subTitle={t("customtEditor.mfg_model.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.mfg_model !== props.selectedItem.mfg_model &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.mfg_model || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="mfg_model"
						value={props.selectedItem.mfg_model || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_help.text")} subTitle={t("customtEditor.mfg_help.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.mfg_help !== props.selectedItem.mfg_help &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.mfg_help || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						name="mfg_help"
						placeholder="https://"
						value={props.selectedItem.mfg_help || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 112px)", marginTop: "10px" }}
					/>
					<ListItemIcon>
						<IconButton disabled={!props.previousItem.mfg_help} component={Link} href={props.previousItem.mfg_help} target="_blank" style={{ marginLeft: "8px" }}>
							<Svg src="navigation/openNewWindow.svg" color={props.previousItem.mfg_help ? theme.palette.primary.main : theme.palette.text.secondary} />
						</IconButton>
					</ListItemIcon>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_fw.text")} subTitle={t("customtEditor.mfg_fw.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.mfg_fw !== props.selectedItem.mfg_fw &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.mfg_fw || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="mfg_fw"
						placeholder="v0.0"
						value={props.selectedItem.mfg_fw || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.public_mfg_name.text")} subTitle={t("customtEditor.public_mfg_name.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.public_mfg_name !== props.selectedItem.public_mfg_name &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.public_mfg_name || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="public_mfg_name"
						placeholder=""
						value={props.selectedItem.public_mfg_name || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.public_mfg_model.text")} subTitle={t("customtEditor.public_mfg_model.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.public_mfg_model !== props.selectedItem.public_mfg_model &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.public_mfg_model || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="public_mfg_model"
						placeholder=""
						value={props.selectedItem.public_mfg_model || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.public_mfg_fw.text")} subTitle={t("customtEditor.public_mfg_fw.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.public_mfg_fw !== props.selectedItem.public_mfg_fw &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.public_mfg_fw || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="public_mfg_fw"
						placeholder=""
						value={props.selectedItem.public_mfg_fw || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
			</Collapse>
		</List>
	);
};

ManufacturerSection.defaultProps = {
	selectedItem: {
		device_info: "hide",
		mfg_fw: "",
		mfg_help: "",
		mfg_model: "",
		mfg_name: "",
		public_mfg_fw: "",
		public_mfg_model: "",
		public_mfg_name: "",
	},
};

ManufacturerSection.propTypes = {
	selectedItem: PropTypes.shape({
		device_info: PropTypes.string,
		mfg_fw: PropTypes.string,
		mfg_help: PropTypes.string,
		mfg_model: PropTypes.string,
		mfg_name: PropTypes.string,
		public_mfg_fw: PropTypes.string,
		public_mfg_model: PropTypes.string,
		public_mfg_name: PropTypes.string,
	}),
	previousItem: PropTypes.shape({
		device_info: PropTypes.string,
		mfg_fw: PropTypes.string,
		mfg_help: PropTypes.string,
		mfg_model: PropTypes.string,
		mfg_name: PropTypes.string,
		public_mfg_fw: PropTypes.string,
		public_mfg_model: PropTypes.string,
		public_mfg_name: PropTypes.string,
	}).isRequired,
	fieldUpdateCount: PropTypes.number.isRequired,
	fieldUpdate: PropTypes.func.isRequired,
};

export default ManufacturerSection;
