import PropTypes from "prop-types";
import { Autocomplete, ListItemText, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

interface Option {
	id: string | null;
	label: string;
	primary: string;
	secondary: string;
}

type Props = {
	name: string;
	placeholder: string;
	value: string;
	options: Array<Option>;
	onChange: (key: string, value: string) => void;
};

const EMPTY_OPTION: Option = {
	id: "",
	label: "",
	primary: "<EMPTY>",
	secondary: ""
};

const handleFilterOptions = createFilterOptions({stringify: (option: Option) => (`${option.primary}#${option.secondary}`)});

const EditableSelectList = (props: Props) => {
	const options = (props.value === "" || props.options.some((option) => (option.id === props.value)))
		? [EMPTY_OPTION, ...props.options]
		: [{id: props.value, label: props.value, primary: "<UNKNOWN>", secondary: props.value}, EMPTY_OPTION, ...props.options];
	return (
		<Autocomplete
			id={`autocomplete_${props.name}`}
			fullWidth={true}
			options={options}
			filterOptions={(options, state) => (
				handleFilterOptions(options, {...state, inputValue: state.inputValue.replace(/["']/g, "")})
			)}
			isOptionEqualToValue={(option, value) => (option.id === value.id)}
			value={options.find((option) => (option.id === props.value))}
			onChange={(event, value/*, reason, details*/) => (
				props.onChange(props.name, value?.id ?? "")
			)}
			renderInput={(params) => (
				<TextField {...params} placeholder={props.placeholder} />
			)}
			renderOption={(props, option/*, state*/) => (
				<li {...props}><ListItemText primary={option.primary} secondary={option.secondary} /></li>
			)}
			style={{ width: "calc(100% - 56px)", float: "right" }}
		/>
	);
};

EditableSelectList.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			primary: PropTypes.string.isRequired,
			secondary: PropTypes.string.isRequired
		})
	).isRequired,
	onChange: PropTypes.func.isRequired
};

export default EditableSelectList;
