import { useTranslation } from "react-i18next";
import { Paper, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
// cmp
import Svg from "./svg";
// hooks
import useAppearance from "../hooks/useAppearance";
// services
import Constants from "../services/constants";
// types
import type { Appearance } from "../types/misc";

const AppDesignSetting = () => {
	const { t } = useTranslation();
	const { appearance, setAppearance } = useAppearance();

	return (
		<Paper component="section" sx={{p: 2}}>
			<Typography variant="h5" gutterBottom={true}>
				{t("generalSettings.appDesign")}
			</Typography>
			<ToggleButtonGroup
				sx={{width: 1}}
				exclusive={true}
				orientation="vertical"
				value={appearance}
				onChange={(event, newAppearance: Appearance | null) => (setAppearance(newAppearance))}
			>
				<ToggleButton sx={{justifyContent: "flex-start"}} value={Constants.Appearance.Light}>
					<Svg src="theme/sunny.svg" style={{marginRight: "12px"}} />{t("generalSettings.light")}
				</ToggleButton>
				<ToggleButton sx={{justifyContent: "flex-start"}} value={Constants.Appearance.Dark}>
					<Svg src="theme/night.svg" style={{marginRight: "12px"}} />{t("generalSettings.dark")}
				</ToggleButton>
				<ToggleButton sx={{justifyContent: "flex-start"}} value={Constants.Appearance.System}>
					<Svg src="theme/systemDefault.svg" style={{marginRight: "12px"}} />{t("generalSettings.system")}
				</ToggleButton>
			</ToggleButtonGroup>
		</Paper>
	);
};

export default AppDesignSetting;
