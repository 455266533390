import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { withTranslation } from "react-i18next";
import {
	Paper,
	CircularProgress,
	List,
	ListItemButton,
	ListItemText
} from "@mui/material";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import TableEditorFilter from "./table-editor-filter";
import TableEditorActions from "./table-editor-actions";
import TableEditorAdvanced from "./table-editor-advanced";
import TableEditorRight from "./table-editor-right";
import AddNewEntryDialog from "./add-new-entry-dialog";
import DeleteDialog from "./delete-dialog";
// services
import Gupport from "../../services/gupport";
import Responsive from "../../services/responsive";
import { Storage, StorageKeys } from "../../services/storage";
import { compareObjects } from "../../services/utils";
import { muiTheme } from "@local/theme";

const ROCID_PREFIXES = ["FLEXP", "GARDENA", "HUE", "INDEGO", "NUKI", "ROC", "TADO", "TUYA", "ZW"];

class TableEditor extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			tableItems: [],
			rocIdPrefixes: {},
			tableItemSelected: null,
			open: false,
			newKey: "",
			newKeyType: "",
			isDuplicate: false,
			filterValue: "",
			searchByRegex: Storage.get(StorageKeys.metadataEditorSearchByRegex) ?? false,
			searchData: Storage.get(StorageKeys.metadataEditorSearchAll) ?? true,
			showRemoveDialog: false,
			containerHeight: 200
		};

		this.refContainerElement = createRef();

		this.handleScreenSizeChanged = this.handleScreenSizeChanged.bind(this);
		this.handleOpenDialogClick = this.handleOpenDialogClick.bind(this);
		this.handleDialogCloseClick = this.handleDialogCloseClick.bind(this);
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleRemoveClick = this.handleRemoveClick.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.checkForExistingKeys = this.checkForExistingKeys.bind(this);
		this.handleSearchByRegexToggle = this.handleSearchByRegexToggle.bind(this);
		this.handleSearchInDataToggle = this.handleSearchInDataToggle.bind(this);
		this.hanldeTableItemUpdate = this.hanldeTableItemUpdate.bind(this);
		this.openRemoveDialog = this.openRemoveDialog.bind(this);
	}

	componentDidMount() {
		Responsive.on("screenSizeChanged", this.handleScreenSizeChanged);

		this.handleScreenSizeChanged();
		this.fetchTable();
	}

	componentDidUpdate(prevProps) {
		if (this.props.tableId !== prevProps.tableId) {
			this.setState({
				loading: true,
				error: null,
				tableItems: [],
				rocIdPrefixes: {},
				tableItemSelected: null,
				open: false,
				newKey: "",
				newKeyType: ""
			}, () => {
				this.fetchTable();
			});
		}
	}

	componentWillUnmount() {
		Responsive.off("screenSizeChanged", this.handleScreenSizeChanged);
	}

	handleScreenSizeChanged() {
		const element = findDOMNode(this.refContainerElement.current); // eslint-disable-line react/no-find-dom-node
		const height = window.innerHeight - element.getBoundingClientRect().top;
		this.setState({
			containerHeight: height - 48
		});
	}

	openRemoveDialog() {
		this.setState({
			showRemoveDialog: true
		});
	}

	static sortTableItems(tableItems) {
		tableItems.sort((aItem, bItem) => {
			if (aItem.id.string_key && bItem.id.string_key) {
				const aText = aItem.id.string_key.toLowerCase();
				const bText = bItem.id.string_key.toLowerCase();
				return (aText > bText) - (bText > aText);
			} else {
				const aText = JSON.stringify(aItem.id).toLowerCase();
				const bText = JSON.stringify(bItem.id).toLowerCase();
				return (aText > bText) - (bText > aText);
			}
		});
		return tableItems;
	}

	getValidKey(newKey) {
		const prefix = Object.keys(this.state.rocIdPrefixes).find((prefix) => (newKey.startsWith(prefix)));
		if (prefix) {
			return this.state.rocIdPrefixes[prefix];
		} else if (!Number.isNaN(Number(newKey))) {
			return this.state.rocIdPrefixes.DEFAULT;
		}
		return newKey;
	}

	getRocIdPrefix(tableItems) {
		const prefixes = {};
		tableItems.forEach((tableItem) => {
			let keyNumber = Number(tableItem.id.string_key);
			let prefix = "DEFAULT";
			const rocIdPrefix = ROCID_PREFIXES.find((rocIdPrefix) => (tableItem.id.string_key.startsWith(rocIdPrefix)));
			if (rocIdPrefix) {
				keyNumber = Number(tableItem.id.string_key.substring(rocIdPrefix.length));
				prefix = rocIdPrefix;
			}
			if (!Number.isNaN(keyNumber)) {
				if (!prefixes.hasOwnProperty(prefix)) {
					prefixes[prefix] = [];
				}
				prefixes[prefix].push(keyNumber);
			}
		});
		const rocIdPrefixes = {};
		Object.keys(prefixes).forEach((prefix) => {
			const newNumber = (Math.max(...prefixes[prefix]) + 1).toString().padStart(4, "0");
			if (prefix === "DEFAULT") {
				rocIdPrefixes[prefix] = newNumber;
			} else {
				rocIdPrefixes[prefix] = prefix + newNumber;
			}
		});
		return rocIdPrefixes;
	}

	fetchTable() {
		Gupport.getTable({
			tableName: this.props.tableId
		}, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					loading: false,
					error: error || msg.payload.data,
					tableItems: [],
					rocIdPrefixes: {}
				});
			} else {
				const tableItems = TableEditor.sortTableItems(msg.payload.data);
				this.setState({
					loading: false,
					error: null,
					tableItems: tableItems,
					filterValue: (this.props.tableId === "rocid_dict") ? (Storage.get(StorageKeys.rocIdFilterQuery) || "") : "",
					rocIdPrefixes: (this.props.tableId === "rocid_dict") ? this.getRocIdPrefix(tableItems) : {}
				});
			}
		});
	}

	hanldeTableItemUpdate(data) {
		const _tableItems = TableEditor.sortTableItems(data);
		const _tableItem = _tableItems.find((tableItem) => (JSON.stringify(tableItem.id) === JSON.stringify(this.state.tableItemSelected?.id)));
		this.setState({
			tableItemSelected: _tableItem,
			tableItems: _tableItems
		});
	}

	handleSelectableListChange(tableItem) {
		this.setState({
			tableItemSelected: tableItem
		});
	}

	checkForExistingKeys(key) {
		return this.state.tableItems.some((tableItem) => {
			if (tableItem.id.string_key) {
				return tableItem.id.string_key.toLowerCase() === key.toLowerCase();
			} else if (typeof tableItem.id === "object") {
				let newKey = "";
				try {
					newKey = JSON.stringify(JSON.parse(key));
				} catch (error) {
					newKey = key;
				}
				return JSON.stringify(tableItem.id).toLowerCase() === newKey.toLowerCase();
			} else {
				return tableItem.id.toLowerCase() === key.toLowerCase();
			}
		});
	}

	isSelected(tableItem) {
		if (this.state.tableItemSelected) {
			if (tableItem.id.string_key) {
				return tableItem.id.string_key === this.state.tableItemSelected.id.string_key;
			} else if (typeof tableItem.id === "object") {
				return JSON.stringify(tableItem.id) === JSON.stringify(this.state.tableItemSelected.id);
			} else {
				return tableItem.id === this.state.tableItemSelected.id;
			}
		} else {
			return false;
		}
	}

	handleOpenDialogClick(isDuplicate, selectedTableItem = null) {
		const tableItem = selectedTableItem ? selectedTableItem : this.state.tableItemSelected;
		const currentTableItem = isDuplicate ? tableItem : this.state.tableItems[0];
		let newKey = "";
		let newKeyType = "string";
		if (currentTableItem && currentTableItem.id.string_key) {
			newKey = currentTableItem.id.string_key;
			newKeyType = typeof currentTableItem.id.string_key;
		} else if (currentTableItem && currentTableItem.id) {
			newKey = JSON.stringify(currentTableItem.id, null, 2);
			newKeyType = typeof currentTableItem.id;
		}

		this.setState({
			open: true,
			newKey: (this.props.tableId === "rocid_dict") ? this.getValidKey(newKey) : newKey,
			newKeyType: newKeyType,
			isDuplicate: isDuplicate,
			tableItemSelected: tableItem
		});
	}

	handleDialogCloseClick(tableItem) {
		this.setState((prevState) => ({
			newKey: prevState.newKey,
			newKeyType: prevState.newKeyType,
			open: false,
			showRemoveDialog: false,
			tableItemSelected: (tableItem && tableItem.id && tableItem.id.string_key) ? tableItem : prevState.tableItemSelected
		}));
	}

	handleAddClick(newKey, templateJson) {
		let id;
		try {
			id = JSON.parse(newKey);
		} catch (error) {
			id = newKey;
		}
		let data = [{
			id: id,
			data: templateJson ? this.props.tableId === "rocid_dict" ? {...templateJson, device_info: "hide"} : templateJson : {},
		}];
		if (this.state.isDuplicate) {
			const obj = structuredClone(this.state.tableItemSelected);
			obj.id = id;
			if (this.props.tableId === "rocid_dict") {
				obj.data.rocid = id;
			}
			data = [obj];
		}
		const payload = {
			tableName: this.props.tableId,
			data: data
		};
		Gupport.updateTable(payload, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					// saving: false,
					error: error || msg.payload.data,
					tableItems: [],
					rocIdPrefixes: {},
					tableItemSelected: null,
					open: false,
					newKey: "",
					newKeyType: ""
				});
			} else {
				const tableItems = TableEditor.sortTableItems(msg.payload.data);
				const tableItemSelected = msg.payload.data.find((item) => (
					item.id.string_key ? item.id.string_key === newKey : compareObjects(item.id, id)
				));
				this.setState({
					// saving: false,
					error: null,
					tableItems: tableItems,
					rocIdPrefixes: (this.props.tableId === "rocid_dict") ? this.getRocIdPrefix(tableItems) : {},
					tableItemSelected: tableItemSelected,
					open: false,
					newKey: "",
					newKeyType: ""
				});
				if (this.props.tableId === "rocid_dict") {
					this.props.navigate(`/teditor/customEditor/${tableItemSelected.id.string_key}`);
				}
			}
		});
	}

	handleDeleteClick(tableItem) {
		this.setState({
			showRemoveDialog: true,
			tableItemSelected: tableItem
		});
	}

	handleRemoveClick() {
		const payload = {
			tableName: this.props.tableId,
			data: [{
				id: (this.state.tableItemSelected.id.string_key) ? this.state.tableItemSelected.id.string_key : this.state.tableItemSelected.id
			}]
		};
		Gupport.deleteTable(payload, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					// saving: false,
					error: error || msg.payload.data,
					tableItems: [],
					rocIdPrefixes: {},
					tableItemSelected: null,
					showRemoveDialog: false
				});
			} else {
				const tableItems = TableEditor.sortTableItems(msg.payload.data);
				this.setState({
					// saving: false,
					error: null,
					tableItems: tableItems,
					rocIdPrefixes: (this.props.tableId === "rocid_dict") ? this.getRocIdPrefix(tableItems) : {},
					tableItemSelected: null,
					showRemoveDialog: false
				});
			}
		});
	}

	getSecondaryText(tableItem) {
		return (
			Object.keys(tableItem.id).map((key) => (
				<span key={key} style={{ display: "flex", marginLeft: "18px" }}>
					<span style={{ textAlign: "left", width: "30%", fontWeight: 500 }}>{key}</span>
					<span style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{String(tableItem.id[key])}</span>
				</span>
			))
		);
	}

	handleFilterChange(value) {
		this.setState({
			filterValue: value
		});
		if (this.props.tableId === "rocid_dict") {
			Storage.set(StorageKeys.rocIdFilterQuery, value);
		}
	}

	getFilteredTableItems() {
		if (this.state.filterValue === "") {
			return this.state.tableItems;
		}

		let filterValue;
		if (this.state.searchByRegex) {
			try {
				filterValue = new RegExp(this.state.filterValue);
			} catch (error) {
				return [];
			}
		} else {
			filterValue = this.state.filterValue.toLowerCase();
		}

		return this.state.tableItems.filter((item) => {
			let found = false;
			if (item.id.hasOwnProperty("string_key")) {
				found = this.filterTest(item.id.string_key, filterValue);
			} else {
				for (const key in item.id) {
					if (item.id.hasOwnProperty(key)) {
						if (typeof item.id[key] === "string" && this.filterTest(item.id[key], filterValue)) {
							found = true;
							break;
						}
					}
				}
			}
			if (!found && this.state.searchData) {
				found = this.filterTest(JSON.stringify(item.data), filterValue);
			}
			return found;
		});
	}

	filterTest(value, filterValue) {
		return this.state.searchByRegex ? filterValue.test(value) : value.toLowerCase().includes(filterValue);
	}

	handleSearchByRegexToggle(event, isInputChecked) {
		this.setState({
			searchByRegex: isInputChecked
		});
		Storage.set(StorageKeys.metadataEditorSearchByRegex, isInputChecked);
	}

	handleSearchInDataToggle(event, isInputChecked) {
		this.setState({
			searchData: isInputChecked
		});
		Storage.set(StorageKeys.metadataEditorSearchAll, isInputChecked);
	}

	render() {
		const { t } = this.props;

		if (this.state.error) {
			return (<h3>{t("generic.errorWithMsg", { msg: this.state.error.message })}</h3>);
		}

		const dynamicHeight = this.state.containerHeight - 36 - 46 - (this.props.hasWriteAccess ? 0 : 60);

		const filteredTableItems = this.getFilteredTableItems();

		return (
			<>
				{(this.props.tableId === "rocid_dict") ?
					<div
						ref={this.refContainerElement}
						style={{ display: "flex", flexDirection: "column", height: this.state.containerHeight }}
					>
						{this.state.loading ?
							<CircularProgress />
							:
							<>
								<div style={{display: "flex", alignItems: "center"}}>
									<TableEditorActions
										isCustomEditor={true}
										hasWriteAccess={this.props.hasWriteAccess}
										tableItemSelected={this.state.tableItemSelected}
										onClickHandler={this.handleOpenDialogClick}
										tableItems={this.state.tableItems}
										tableId={this.props.tableId}
										loading={this.state.loading}
									/>
								</div>
								<Paper style={{ width: "100%" }}>
									<TableEditorFilter
										isRocidDict={true}
										filterValue={this.state.filterValue}
										searchAll={this.state.searchData}
										searchByRegex={this.state.searchByRegex}
										onFilterChange={this.handleFilterChange}
										onSearchByRegexToggle={this.handleSearchByRegexToggle}
										onSearchInDataToggle={this.handleSearchInDataToggle}
										searchResultLength={filteredTableItems.length}
									/>
									<TableEditorAdvanced
										hasWriteAccess={this.props.hasWriteAccess}
										filteredData={filteredTableItems}
										onDuplicateClickHandler={this.handleOpenDialogClick}
										onDeleteClick={this.handleDeleteClick}
									/>
								</Paper>
							</>
						}
					</div>
					:
					<div
						ref={this.refContainerElement}
						style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 5, height: this.state.containerHeight }}
					>
						<div style={{ width: "34%", paddingRight: "16px" }}>
							<TableEditorActions
								hasWriteAccess={this.props.hasWriteAccess}
								tableItemSelected={this.state.tableItemSelected}
								onClickHandler={this.handleOpenDialogClick}
								tableItems={this.state.tableItems}
								tableId={this.props.tableId}
								loading={this.state.loading}
							/>
							<Paper>
								<TableEditorFilter
									isRocidDict={false}
									filterValue={this.state.filterValue}
									searchAll={this.state.searchData}
									searchByRegex={this.state.searchByRegex}
									onFilterChange={this.handleFilterChange}
									onSearchByRegexToggle={this.handleSearchByRegexToggle}
									onSearchInDataToggle={this.handleSearchInDataToggle}
									searchResultLength={filteredTableItems.length}
								/>
								{this.state.loading ? <CircularProgress /> :
									<List style={{ overflow: "auto", padding: 0, height: dynamicHeight }}>
										{filteredTableItems.map((tableItem, index) => (
											<ListItemButton
												key={index}
												selected={this.isSelected(tableItem)}
												style={this.isSelected(tableItem) ? { backgroundColor: muiTheme.palette.action.selected } : {}}
												onClick={this.handleSelectableListChange.bind(this, tableItem)}
											>
												<ListItemText
													primary={tableItem.id._description || tableItem.id.string_key || t("teditor.objectTitle")}
													secondary={this.getSecondaryText(tableItem)}
												/>
											</ListItemButton>
										))}
									</List>
								}
							</Paper>
						</div>
						<TableEditorRight
							tableId={this.props.tableId}
							hasWriteAccess={this.props.hasWriteAccess}
							tableItemSelected={this.state.tableItemSelected}
							containerHeight={this.state.containerHeight}
							onItemUpdate={this.hanldeTableItemUpdate}
							onRemoveClick={this.openRemoveDialog}
						/>
					</div>
				}
				{this.state.open &&
					<AddNewEntryDialog
						newKey={this.state.newKey}
						newKeyType={this.state.newKeyType}
						rocIdPrefixes={this.state.rocIdPrefixes}
						isDuplicate={this.state.isDuplicate}
						checkForExistingKeys={this.checkForExistingKeys}
						isRocIdDict={this.props.tableId === "rocid_dict"}
						onAddClick={this.handleAddClick}
						onCloseHandler={this.handleDialogCloseClick}
					/>
				}
				<DeleteDialog
					open={this.state.showRemoveDialog}
					handleClose={this.handleDialogCloseClick}
					handleRemoveClick={this.handleRemoveClick}
				/>
			</>
		);
	}

}

TableEditor.propTypes = {
	tableId: PropTypes.string.isRequired,
	hasWriteAccess: PropTypes.bool.isRequired,
	navigate: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(withNavigateAndParams(TableEditor));
