import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// cmp
import MetadataJSONEditor from "./metadata-json-editor";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { icons } from "@local/theme";

class TableEditorRight extends Component {

	constructor(props) {
		super(props);

		this.state = {
			inProgress: false,
			data: {},
			dataString: "",
			dataStringValid: true
		};

		this.handleActionJsonChange = this.handleActionJsonChange.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.tableId !== prevProps.tableId) {
			this.setState({
				data: {},
				dataString: "",
				dataStringValid: true
			});
		}
		if (JSON.stringify(this.props.tableItemSelected) !== JSON.stringify(prevProps.tableItemSelected)) {
			if (this.props.tableItemSelected === null) {
				this.setState({
					data: {},
					dataString: "",
					dataStringValid: true
				});
			} else {
				this.setState({
					data: this.props.tableItemSelected.data,
					dataString: JSON.stringify(this.props.tableItemSelected.data, null, 2),
					dataStringValid: true,
					inProgress: false
				});
			}
		}
	}

	handleActionJsonChange(value) {
		try {
			const json = JSON.parse(value);
			this.setState({
				data: json,
				dataString: value,
				dataStringValid: true
			});
		} catch (error) {
			this.setState({
				dataString: value,
				dataStringValid: false
			});
		}
	}

	handleSaveClick() {
		this.setState({
			inProgress: true
		});
		const payload = {
			tableName: this.props.tableId,
			data: [{
				id: (this.props.tableItemSelected.id.string_key) ? this.props.tableItemSelected.id.string_key : this.props.tableItemSelected.id,
				data: this.state.data
			}]
		};
		Gupport.updateTable(payload, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					inProgress: false,
					data: {},
					dataString: "",
					dataStringValid: true
				});
			} else {
				const tableItem = msg.payload.data.find((tableItem) => (JSON.stringify(tableItem.id) === JSON.stringify(this.props.tableItemSelected.id)));
				this.setState({
					inProgress: false,
					data: tableItem.data,
					dataString: JSON.stringify(tableItem.data, null, 2),
					dataStringValid: true
				});
				this.props.onItemUpdate(msg.payload.data);
			}
		});
	}

	render() {
		const { t } = this.props;
		const dynamicHeight = this.props.containerHeight - 36 - 46 - (this.props.hasWriteAccess ? 0 : 60);

		return (
			<div style={{ width: "66%", paddingLeft: "16px" }}>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "48px" }}>
					<div style={{ fontWeight: "bold" }}>{t("teditor.data")}</div>
					<div>
						<LoadingButton
							variant="contained"
							size="small"
							startIcon={<icons.SaveIcon />}
							loading={this.state.inProgress}
							disabled={this.props.tableItemSelected === null || !this.state.dataStringValid || !this.props.hasWriteAccess || JSON.stringify(this.props.tableItemSelected.data) === JSON.stringify(this.state.data)}
							onClick={this.handleSaveClick}
							style={{marginRight: "10px"}}
						>
							{t("teditor.save")}
						</LoadingButton>
						<Button
							variant="contained"
							color="danger"
							size="small"
							startIcon={<Svg src="actions/delete.svg" size={18} />}
							disabled={(this.props.tableItemSelected === null || !this.props.hasWriteAccess)}
							onClick={this.props.onRemoveClick}
						>
							{t("teditor.remove")}
						</Button>
					</div>
				</div>
				{this.props.tableItemSelected
					?
					<div style={{ overflow: "auto", marginTop: "16px", height: dynamicHeight + 56 }}>
						<MetadataJSONEditor dataString={this.state.dataString} onActionJsonChange={this.handleActionJsonChange} />
					</div>
					:
					<h4 style={{ padding: "1em", textAlign: "center" }}>
						{t("teditor.selectAnItem")}
					</h4>
				}
			</div>
		);
	}

}

TableEditorRight.defaultProps = {
	tableItemSelected: null
};

TableEditorRight.propTypes = {
	tableId: PropTypes.string.isRequired,
	hasWriteAccess: PropTypes.bool.isRequired,
	tableItemSelected: PropTypes.object,
	containerHeight: PropTypes.number.isRequired,
	onItemUpdate: PropTypes.func.isRequired,
	onRemoveClick: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(TableEditorRight);
