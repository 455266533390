import { useState } from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Typography,
	Avatar,
	TextField,
	Switch,
	ListItemIcon,
	Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import EditableSelectList from "../editable-select";
import Svg from "../../../svg";
// services
import { icons } from "@local/theme";
// types
import type { ChangeEvent } from "react";

interface Info {
	bat_type?: string;
	rocid?: string;
	category?: string;
	type?: string;
	endpoint?: string;
	quickview_category?: string;
	is_group?: boolean;
	is_presence?: boolean;
	plugin?: string;
	color?: string;
}

type Props = {
	selectedItem?: Info;
	previousItem: Info;
	fieldUpdateCount: number;
	onChange: (key: string, value: string) => void;
	fieldUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
	onToggleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

interface Option {
	id: string;
	label: string;
	primary: string;
	secondary: string;
}

const createOptionsFromL10ns = (l10ns: Record<string, string>): Array<Option> => (
	Object.keys(l10ns).map((l10n) => ({
		id: l10n,
		label: l10n,
		primary: l10ns[l10n] ?? l10n,
		secondary: l10n
	}))
);

const OPTIONS = {
	DC: createOptionsFromL10ns(i18next.t("deviceCategories", {returnObjects: true})),
	DT: createOptionsFromL10ns(i18next.t("rocIds", { returnObjects: true })),
	QC: createOptionsFromL10ns(i18next.t("quickCategories", { returnObjects: true }))
} as const;

const InfoSection = (props: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(true);

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.info")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{props.fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.rocid.text")} subTitle={t("customtEditor.rocid.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.rocid !== props.selectedItem?.rocid &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.rocid ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="rocid"
						placeholder="0000"
						disabled={true}
						value={props.selectedItem?.rocid ?? ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.bat_type.text")} subTitle={t("customtEditor.bat_type.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.bat_type !== props.selectedItem?.bat_type &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.bat_type ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="bat_type"
						value={props.selectedItem?.bat_type ?? ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.category.text")} subTitle={t("customtEditor.category.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.category !== props.selectedItem?.category &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.category ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<EditableSelectList
						name="category"
						placeholder="dc_*"
						value={props.selectedItem?.category ?? ""}
						options={OPTIONS.DC}
						onChange={props.onChange}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.type.text")} subTitle={t("customtEditor.type.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.type !== props.selectedItem?.type &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.type ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<EditableSelectList
						name="type"
						placeholder="dt_*"
						value={props.selectedItem?.type ?? ""}
						options={OPTIONS.DT}
						onChange={props.onChange}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.endpoint.text")} subTitle={t("customtEditor.endpoint.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.endpoint !== props.selectedItem?.endpoint &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.endpoint ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="endpoint"
						placeholder="00"
						value={props.selectedItem?.endpoint ?? ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.quickview_category.text")} subTitle={t("customtEditor.quickview_category.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.quickview_category !== props.selectedItem?.quickview_category &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.quickview_category ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<EditableSelectList
						name="quickview_category"
						placeholder="qc_*"
						value={props.selectedItem?.quickview_category ?? ""}
						options={OPTIONS.QC}
						onChange={props.onChange}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.is_group.text")} subTitle={t("customtEditor.is_group.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.is_group !== props.selectedItem?.is_group &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.is_group === undefined ? "NA" : props.previousItem.is_group.toString()}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<div style={{ width: "calc(100% - 56px)" }}>
						<Switch
							name="is_group"
							checked={props.selectedItem?.is_group ?? false}
							onChange={props.onToggleChange}
						/>
					</div>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.is_presence.text")} subTitle={t("customtEditor.is_presence.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.is_presence !== props.selectedItem?.is_presence &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.is_presence === undefined ? "NA" : props.previousItem.is_presence.toString()}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<div style={{ width: "calc(100% - 56px)" }}>
						<Switch
							name="is_presence"
							checked={props.selectedItem?.is_presence ?? false}
							onChange={props.onToggleChange}
						/>
					</div>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.plugin.text")} subTitle={t("customtEditor.plugin.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.plugin !== props.selectedItem?.plugin &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.plugin ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="plugin"
						value={props.selectedItem?.plugin ?? ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.color.text")} subTitle={t("customtEditor.color.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.color !== props.selectedItem?.color &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.color ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="color"
						placeholder="#RRGGBBAA"
						value={props.selectedItem?.color ?? ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
			</Collapse>
		</List>
	);
};

InfoSection.defaultProps = {
	selectedItem: {
		bat_type: "",
		rocid: "",
		category: "",
		type: "",
		endpoint: "",
		quickview_category: "",
		is_group: false,
		is_presence: false,
		plugin: "",
		color: ""
	}
};

InfoSection.propTypes = {
	selectedItem: PropTypes.shape({
		bat_type: PropTypes.string,
		rocid: PropTypes.string,
		category: PropTypes.string,
		type: PropTypes.string,
		endpoint: PropTypes.string,
		quickview_category: PropTypes.string,
		is_group: PropTypes.bool,
		is_presence: PropTypes.bool,
		plugin: PropTypes.string,
		color: PropTypes.string
	}),
	previousItem: PropTypes.shape({
		bat_type: PropTypes.string,
		rocid: PropTypes.string,
		category: PropTypes.string,
		type: PropTypes.string,
		endpoint: PropTypes.string,
		quickview_category: PropTypes.string,
		is_group: PropTypes.bool,
		is_presence: PropTypes.bool,
		plugin: PropTypes.string,
		color: PropTypes.string
	}).isRequired,
	fieldUpdateCount: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	fieldUpdate: PropTypes.func.isRequired,
	onToggleChange: PropTypes.func.isRequired
};

export default InfoSection;
