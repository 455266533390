import { Component, Children } from "react";
import PropTypes from "prop-types";
// services
import Responsive from "../services/responsive";

class ResponsiveLayout extends Component {

	constructor(props) {
		super(props);

		this.state = {
			screens: Responsive.getScreenSizes()
		};

		this.handleScreenChanged = this.handleScreenChanged.bind(this);
	}

	componentDidMount() {
		Responsive.on("screenChanged", this.handleScreenChanged);
	}

	componentWillUnmount() {
		Responsive.off("screenChanged", this.handleScreenChanged);
	}

	handleScreenChanged() {
		this.setState({
			screens: Responsive.getScreenSizes()
		});
	}

	render() {
		const children = Children.toArray(this.props.children);

		for (let i = 0; i < this.state.screens.length; i++) {
			const match = children.find((child) => (child.props.screen === this.state.screens[i]));
			if (match) {
				return match;
			}
		}

		return null;
	}

}

ResponsiveLayout.propTypes = {
	children: PropTypes.node.isRequired
};

export default ResponsiveLayout;
