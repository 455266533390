import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from "@mui/material";

type Props = {
	errorMsg: string;
	onClose: () => void;
};

const ErrorDialog = (props: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			fullWidth={true}
			open={true}
			onClose={props.onClose}
		>
			<DialogTitle>{t("customtEditor.errors.title")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.errorMsg}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="inherit" onClick={props.onClose}>{t("dialog.close")}</Button>
			</DialogActions>
		</Dialog>
	);
};

ErrorDialog.propTypes = {
	errorMsg: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
};

export default ErrorDialog;
