import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import ExportBackup from "./export-backup";
import ImportBackup from "./import-backup";
// types
import type { ValueOf } from "type-fest";

const TAB_IDS = {
	EXPORT: "export",
	IMPORT: "import"
} as const;

type TabId = ValueOf<typeof TAB_IDS>;

const BackupTabs = () => {
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState<TabId>(TAB_IDS.EXPORT);

	return (
		<TabContext value={selectedTab}>
			<Paper component="section" style={{ marginBottom: "12px" }}>
				<TabList onChange={(event, value: TabId) => (setSelectedTab(value))}>
					<Tab
						id={`tab-backup-${TAB_IDS.EXPORT}`}
						label={t("backup.export")}
						value={TAB_IDS.EXPORT}
					/>
					<Tab
						id={`tab-backup-${TAB_IDS.IMPORT}`}
						label={t("backup.import")}
						value={TAB_IDS.IMPORT}
					/>
				</TabList>
			</Paper>
			<TabPanel value={TAB_IDS.EXPORT}><ExportBackup /></TabPanel>
			<TabPanel value={TAB_IDS.IMPORT}><ImportBackup /></TabPanel>
		</TabContext>
	);
};

export default BackupTabs;
